import React from 'react'

// Components
import FindPwComponent from '../../../../components/user/find-account/Pw'

const FindPwCounselor = () => {
   return (
      <FindPwComponent isCounselor={true} />
   )
}

export default FindPwCounselor