import React from 'react'

// Components
import FindPwComponent from '../../../../components/user/find-account/Pw'

const FindPw = () => {
   return (
      <FindPwComponent isCounselor={false} />
   )
}

export default FindPw