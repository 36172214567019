import React from 'react'

// Components
import JoinOptions from '../../../../components/user/JoinOptions'

const JoinGeneralUser = () => {
   return (
      <JoinOptions isCounselor={false} />
   )
}

export default JoinGeneralUser