import React from 'react'

// Components
import IndividaulForm from '../../../../components/user/join-form/Individaul'

const GeneralUserEmail = () => {
   return (
      <IndividaulForm isSocial={false} />
   )
}

export default GeneralUserEmail