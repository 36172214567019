import React from 'react'

// Components
import CounselorForm from '../../../../components/user/join-form/Counselor'

const CounselorEmail = () => {
   return (
      <CounselorForm isSocial={false} />
   )
}

export default CounselorEmail