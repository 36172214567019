import React from 'react'

const LongTimeLoading = () => {
   return (
      <div className='loading-modal-wrap'>
         <div className='content'>
            <i className='xi-spinner-1 xi-spin icon-spin'></i>
            <p className='text'>잠시만 기다려주세요!</p>
         </div>
      </div>
   )
}

export default LongTimeLoading